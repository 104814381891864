



























import MNotification from "@/mixins/MNotification.vue";
import { RequestClosePurchaseOrder } from "@/models/interface/purchase.interface";
import { purchaseServices } from "@/services/purchase.service";
import Vue from "vue";

export default Vue.extend({
  name: "PurchaseOrderCloseForm",
  mixins: [MNotification],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    params: {
      type: Object as () => { ids: string[]; docNumbers: string[] },
      required: false,
      default: () => ({ ids: [], docNumbers: [] }),
    },
  },
  data() {
    return {
      formState: {
        reason: "",
      },
      loading: false,
    };
  },
  methods: {
    onClose(): void {
      this.formState.reason = "";
      this.$emit("close");
      this.$emit("input", false);
    },
    handleSubmit(): void {
      const payload: RequestClosePurchaseOrder = {
        reasonToClose: this.formState.reason,
      };
      const poIds: string = this.params.ids.join(",");

      this.loading = true;
      purchaseServices
        .closePurchaseOrder(payload, poIds)
        .then(response => {
          const docNumbers = response
            .map(item => Boolean(item.documentNumber) && item.documentNumber)
            .join(", ");
          this.showNotifSuccess("lbl_successfully_close_po_text", {
            text: docNumbers,
          });
          this.onClose();
          this.$emit("finish");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
