var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        "cancel-text": _vm.$t("lbl_cancel"),
        "ok-text": _vm.$t("lbl_close_po"),
        title: _vm.$t("lbl_close_po"),
        "destroy-on-close": "",
        "ok-button-props": { props: { loading: _vm.loading } }
      },
      on: { cancel: _vm.onClose, ok: _vm.handleSubmit }
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.$t("lbl_purchase_order_close_info")))]),
      _c("p", [_vm._v(_vm._s(_vm.params.docNumbers.join(", ")))]),
      _c(
        "a-form-model",
        { attrs: { model: _vm.formState } },
        [
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_reason_to_close_po") },
              scopedSlots: _vm._u([
                {
                  key: "extra",
                  fn: function() {
                    return [
                      _c("character-length", {
                        attrs: { value: _vm.formState.reason }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("a-textarea", {
                attrs: {
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_reason")
                  })
                },
                model: {
                  value: _vm.formState.reason,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "reason", $$v)
                  },
                  expression: "formState.reason"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }