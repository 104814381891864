












































































































































































import { ModalStateBuilder, SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import SelectPurchaseOrder from "@/components/custom/select/SelectPurchaseOrder.vue";
import SelectPurchaseRequisition from "@/components/custom/select/SelectPurchaseRequisition.vue";
import SelectSupplier from "@/components/custom/select/SelectSupplier.vue";
import { PurchaseOrderCloseForm } from "@/components/PurchaseOrder";
import dateFormat from "@/filters/date.filter";
import { setNumbering } from "@/helpers/common";
import { APagination, useDate } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { PURCHASE_ORDER_STATUS } from "@/models/enums/purchase-order.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { PurchaseOrderResponseDto } from "@/models/interface/purchase-order";
import { purchaseServices } from "@/services/purchase.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import Vue from "vue";

type FormState = {
  branch: LabelInValue | undefined;
  supplier: LabelInValue | undefined;
  date: Moment[] | null;
  prNumber: LabelInValue | undefined;
  poNumber: LabelInValue | undefined;
  poType: string | undefined;
  status: string | undefined;
};

type Record = {
  no: number;
} & PurchaseOrderResponseDto;

export default Vue.extend({
  name: "IndexPage",
  components: {
    SelectBranch,
    SelectSupplier,
    SelectPurchaseRequisition,
    SelectPurchaseOrder,
    SelectMasterType,
    PurchaseOrderCloseForm,
  },
  data() {
    return {
      PAGE_SIZE_OPTIONS,
      DEFAULT_DATE_FORMAT,
      modalState: ModalStateBuilder.build<{
        ids: string[];
        docNumbers: string[];
      }>({
        ids: [],
        docNumbers: [],
      }),
      formState: {
        branch: undefined,
        supplier: undefined,
        date: [],
        prNumber: undefined,
        poNumber: undefined,
        poType: undefined,
        status: undefined,
      } as FormState,
      formRef: null as null | FormModel,
      dataSource: [] as Record[],
      selectedRowKeys: [] as string[],
      pagination: {
        page: 1,
        totalElements: 0,
        limit: 10,
      },
      loading: {
        submit: false,
        download: false,
      },
      columns: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          key: "no",
          width: 70,
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchWarehouseName",
          key: "branchWarehouseName",
          width: 120,
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_purchase_order_type"),
          dataIndex: "poType",
          key: "poType",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_pr_number"),
          dataIndex: "purchaseRequisitionNo",
          key: "purchaseRequisitionNo",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_purchase_order_number"),
          dataIndex: "documentNumber",
          key: "documentNumber",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_supplier_name"),
          dataIndex: "supplierName",
          key: "supplierName",
          ellipsis: true,
          scopedSlots: { customRender: "supplier" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "date",
          key: "date",
          width: 150,
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          width: 170,
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_created_by"),
          dataIndex: "createdBy",
          key: "createdBy",
          width: 250,
        },
        {
          title: this.$t("lbl_action"),
          key: "action",
          align: "right",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted(): void {
    if (this.$refs.formRef) {
      this.formRef = this.$refs.formRef as FormModel;
    }

    this.getPurchaseOrderList();
  },
  methods: {
    handleReset(): void {
      this.formRef?.resetFields();
      this.pagination.page = -1;
      this.getPurchaseOrderList();
    },
    handleSubmit(): void {
      this.selectedRowKeys = [];
      this.pagination.page = 1;
      const params = this.buildParams({
        state: this.formState,
        page: this.pagination.page,
      });
      this.getPurchaseOrderList(params);
    },
    getPurchaseOrderList(params?: RequestQueryParamsModel): void {
      this.loading.submit = true;
      purchaseServices
        .getListPurchaseOrderNew(params)
        .then(response => {
          this.pagination.totalElements = response.totalElements;
          this.dataSource = response.data.map((item, index) => {
            return {
              ...item,
              no: setNumbering(params?.page, params?.limit, index),
            };
          });
        })
        .finally(() => {
          this.loading.submit = false;
        });
    },
    buildParams(arg: {
      state: FormState;
      page?: number;
      limit?: number;
    }): RequestQueryParamsModel {
      const { toStartDay, toEndDay } = useDate();
      const { state, page = 1, limit = 10 } = arg;
      const params = new RequestQueryParams();
      params.page = page - 1;
      params.limit = limit;
      params.sorts = "date:desc";
      const queries: string[] = [];

      if (state.branch) {
        const searchByBranch = new SearchBuilder()
          .push(["branch.secureId", state.branch.key])
          .build();
        queries.push(searchByBranch);
      }

      if (state.supplier) {
        const searchBySupplier = new SearchBuilder()
          .push(["supplier.secureId", state.supplier.key])
          .build();
        queries.push(searchBySupplier);
      }

      if (state.date && state.date.length === 2) {
        const [start, end] = state.date;
        const query = new SearchBuilder()
          .push(["date", toStartDay(start).format()], { het: true })
          .and()
          .push(["date", toEndDay(end).format()], { let: true })
          .build();
        queries.push(query);
      }

      if (state.prNumber) {
        const searchByPr = new SearchBuilder()
          .push(["purchaseRequisition.secureId", state.prNumber.key])
          .build();
        queries.push(searchByPr);
      }

      if (state.poNumber) {
        const searchByPo = new SearchBuilder()
          .push(["secureId", state.poNumber.key])
          .build();
        queries.push(searchByPo);
      }

      if (state.poType) {
        const searchByPoType = new SearchBuilder()
          .push(["poType", state.poType])
          .build();
        queries.push(searchByPoType);
      }

      if (state.status) {
        const searchByStatus = new SearchBuilder()
          .push(["status", state.status])
          .build();
        queries.push(searchByStatus);
      }

      params.search = queries.join(SearchBuilder.AND);
      return params;
    },
    onChangeTable({ current, pageSize }: APagination): void {
      this.pagination.page = pageSize === this.pagination.limit ? current : 1;
      this.pagination.limit = pageSize;
      const params = this.buildParams({
        state: this.formState,
        page: this.pagination.page,
        limit: this.pagination.limit,
      });
      this.getPurchaseOrderList(params);
    },
    handleClosePo(): void {
      this.modalState.props.ids = this.selectedRowKeys;
      this.modalState.open();
    },
    onSelectRow(keys: string[], selectedRows: Record[]): void {
      this.selectedRowKeys = keys;
      this.modalState.props.docNumbers = selectedRows.map(
        record => record.documentNumber
      );
    },
    onFinishClose(): void {
      const params = this.buildParams({
        state: this.formState,
        page: this.pagination.page,
        limit: this.pagination.limit,
      });
      this.getPurchaseOrderList(params);
    },
    allowClosePo(record: Record): boolean {
      const allowed = [
        PURCHASE_ORDER_STATUS.PARTIALLY_RECEIVED,
        PURCHASE_ORDER_STATUS.PARTIAL_BILLED,
      ];
      return allowed.includes(record.status);
    },
  },
});
