




















import { SearchBuilder } from "@/builder";
import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { purchaseRequisitionServices } from "@/services/purchaseRequisition.service";
import { LabelInValue } from "@/types";
import Vue from "vue";

export default Vue.extend({
  name: "SelectPurchaseRequisition",
  components: {
    Select,
  },
  props: {
    value: {
      type: Object as () => LabelInValue,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      options: [] as Option[],
      loading: false,
      pagination: {
        page: 1,
        search: undefined as string | undefined,
      },
      allLoaded: false,
    };
  },
  created(): void {
    this.onSearch = debounceProcess(this.onSearch);
    this.getList(this.buildParams({ page: 1 }));
  },
  methods: {
    buildParams(arg: {
      page: number;
      docNumber?: string;
    }): RequestQueryParamsModel {
      const params = new RequestQueryParams();
      params.sorts = "createdDate:desc";
      params.page = arg.page - 1;

      if (arg.docNumber) {
        params.search = new SearchBuilder()
          .push(["documentNo", arg.docNumber], {
            like: "both",
          })
          .build();
      }

      return params;
    },
    loadMore(): void {
      if (this.loading || this.allLoaded) {
        return;
      }

      this.pagination.page += 1;
      this.getList(
        this.buildParams({
          page: this.pagination.page,
          docNumber: this.pagination.search,
        })
      );
    },
    resetState(): void {
      this.pagination.page = 1;
      this.pagination.search = undefined;
      this.options = [];
      this.allLoaded = false;
    },
    onSearch(value?: string): void {
      this.resetState();
      this.pagination.search = value;
      this.getList(this.buildParams({ page: 1, docNumber: value }));
    },
    onChange(val?: LabelInValue): void {
      if (!val) {
        const params = new RequestQueryParams();
        params.sorts = "createdDate:desc";
        this.getList(params);
      }

      this.$emit("change", val);
      this.$emit("input", val);
    },
    getList(params?: RequestQueryParamsModel): void {
      this.loading = true;
      purchaseRequisitionServices
        .listPurchaseRequisition(params)
        .then(response => {
          this.allLoaded = response.currentPage + 1 === response.totalPages;
          const options: Option[] = response.data
            .filter(item => {
              return Boolean(item.documentNo);
            })
            .map(item => {
              return {
                label: item.documentNo,
                value: item.id,
                key: item.id,
              };
            });
          this.options = [...this.options, ...options];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
